// @flow
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { markCompanyPinned } from 'domain/companies/companiesActions';
import { searchTextSelector, searchTypeSelector, type CompaniesList } from 'domain/companies/companiesSelector';
import { unreadRequestsSelector } from 'domain/documents/documentSelector';
import { rtlEnable } from 'domain/env/envSelector';
import * as ACL from 'domain/restriction';
import { generatePath } from 'react-router-dom';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import elements from 'components/elements';

import {
  CardBadge,
  CardPendingApprovalBadge,
  CardActionArea,
  CardActions,
  CardActionAreaOverlay,
} from 'pages/companies/components/Grid/Card/StyledComponents';
import RouterLink from 'components/mui/Router/RouterLink';
import Tooltip from 'components/mui/Tooltip';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import InsertDriveFileTwoToneIcon from '@mui/icons-material/InsertDriveFileTwoTone';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';
import { SEARCH_TYPES } from 'domain/companies/helpers';

type TGridItem = {
  data: {
    list: CompaniesList,
    gap: number,
    columnCount: number,
  },
  columnIndex: number,
  rowIndex: number,
  style: {
    position: 'string',
    left: number,
    right: number,
    top: number,
    width: number,
    height: number,
  },
};

const getPathToCompany = ({ searchType, isSupplier, searchText, companyId }) => {
  const pathBase = isSupplier
    ? generatePath(ROUTES_PATH.COMPANY_SUPPLIER.absolute, { companyId })
    : generatePath(ROUTES_PATH.COMPANY_DEFAULT_CATEGORY.absolute, { companyId });

  const pathAllCategory = isSupplier
    ? generatePath(ROUTES_PATH.COMPANY_SUPPLIER.absolute, { companyId, category1: 30 })
    : generatePath(ROUTES_PATH.COMPANY_WORKSPACE.absolute, { companyId, category1: 30 });

  switch (searchType) {
    case SEARCH_TYPES.byDocumentText:
      return searchText && searchText.length > 2 ? `${pathAllCategory}?stag[]=${searchText}` : pathBase;
    case SEARCH_TYPES.byCompany:
    default:
      return pathBase;
  }
};

const mapPropsToState = (state) => ({
  isGranted: ACL.isGranted(state),
  isRtl: rtlEnable(state),
  searchText: searchTextSelector(state),
  searchType: searchTypeSelector(state),
});

const GridCard: React$StatelessFunctionalComponent<TGridItem> = ({
  data: { list, gap, columnCount },
  columnIndex,
  rowIndex,
  style,
}) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const [isRaised, setIsRaised] = useState(false);

  const entry = list.get(rowIndex * columnCount + columnIndex);

  const { isRtl, isGranted, searchText, searchType } = useSelector(mapPropsToState);
  const unreadRequests = useSelector(unreadRequestsSelector).filter((r) => r.companyId === entry?.id);

  if (!entry) return null;

  const isErpConnected = entry.get('isConnectedToErp');
  const notifications = entry.notifications + unreadRequests.size;
  const pathToCompany = getPathToCompany({
    searchType,
    isSupplier: isGranted(ACL.IS_SUPPLIER_USER),
    searchText,
    companyId: entry.id,
  });
  const pathToSettings = generatePath(ROUTES_PATH.COMPANY_SETTINGS_INTEGRATIONS.absolute, {
    companyId: entry.id,
  });
  const pathTo = isErpConnected ? pathToCompany : pathToSettings;
  const gapSide = isRtl ? 'right' : 'left';

  const onMouseOver = () => setIsRaised(true);
  const onMouseOut = () => setIsRaised(false);

  const onClickFavorite = () => {
    dispatch(markCompanyPinned({ params: { id: entry.id } }));
  };

  return (
    <Card
      raised={isRaised}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseOut}
      data-element={elements.content.companies.item}
      style={{
        ...style,
        height: style.height - gap,
        [`${gapSide}`]: columnIndex ? style[gapSide] + gap * columnIndex : style[gapSide],
      }}
    >
      <CardActionArea component={RouterLink} href={pathTo}>
        <CardMedia
          component="img"
          height="120"
          image={entry.get('logo')}
          alt="Company logo"
          loading="lazy"
          sx={{ objectFit: 'contain' }}
        />
        {!isErpConnected && (
          <CardActionAreaOverlay>
            {isRaised && (
              <Button sx={{ margin: 'auto' }}>
                {formatMessage({
                  id: 'button.settings',
                  defaultMessage: 'Settings',
                })}
              </Button>
            )}
          </CardActionAreaOverlay>
        )}
        <CardContent sx={{ position: 'relative', zIndex: 0 }}>
          <Typography variant="subtitle1" textAlign="center" noWrap>
            <Tooltip t={entry.get('cname')}>{entry.get('cname')}</Tooltip>
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Box display="flex" alignItems="center" gap={2} mr={1}>
          {isErpConnected ? (
            <>
              {notifications > 0 && (
                <CardBadge badgeContent={notifications} color="primary">
                  <EmailTwoToneIcon color="primary" />
                </CardBadge>
              )}
              {entry.unprocessed > 0 && (
                <CardBadge badgeContent={entry.unprocessed} max={99} color="primary">
                  <InsertDriveFileTwoToneIcon color="primary" />
                </CardBadge>
              )}
              {entry.pendingApproval > 0 && (
                <Tooltip
                  t={formatMessage({
                    id: 'companies.companyItem.pendingApproval.tooltip',
                    defaultMessage: 'Documents pending approval',
                  })}
                >
                  <CardPendingApprovalBadge badgeContent={entry.pendingApproval} max={99}>
                    <PublishedWithChangesOutlinedIcon color="warning" />
                  </CardPendingApprovalBadge>
                </Tooltip>
              )}
            </>
          ) : (
            <Tooltip
              t={formatMessage({
                id: 'companies.companyItem.erpIsNotConnected.tooltip',
                defaultMessage: 'ERP is not connected',
              })}
            >
              <ErrorOutlinedIcon color="error" />
            </Tooltip>
          )}
        </Box>
        <Rating max={1} value={entry.get('pinned') ? 1 : 0} onChange={onClickFavorite} sx={{ ml: 'auto' }} />
      </CardActions>
    </Card>
  );
};

export default GridCard;
