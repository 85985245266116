// @flow

import * as React from 'react';

import { withStyles } from '@mui/styles';
import cx from 'classnames';

import type { erpNamesType } from 'domain/companies/types.js.flow';

import sheet from './sheet';

import HASH from './images/HASH.svg';
import PRIORITY_PRO from './images/PRIORITY_PRO.svg';
import MICROSOFT_DYNAMICS_NAV from './images/MD-Navision.svg';
import MICROSOFT_DYNAMICS_BC from './images/MD-BC.svg';
import QBD from './images/QBD.svg';
import QBO from './images/QBO.svg';
import SAGE from './images/SAGE.svg';
import SAGE_SA from './images/SAGE_SA.svg';
import XERO from './images/XERO.svg';
import ZOHO from './images/ZOHO.svg';
import DOKKA from './images/DOKKA.png';
import NETSUITE from './images/NETSUITE.png';
import SAP from './images/SAP.png';
import DEFAULT_ERP from './images/DEFAULT.svg';
import ACUMATICA from './images/ACUMATICA.png';
import HASH_WEB from './images/HASH_WEB.png';

const images = {
  HASH,
  PRIORITY_PRO,
  PRIORITY_PRO_SQL: PRIORITY_PRO,
  MICROSOFT_DYNAMICS_NAV,
  MICROSOFT_DYNAMICS_BC,
  QBO,
  QBD,
  SAGE,
  SAGE_SA,
  XERO,
  ZOHO,
  DOKKA,
  FOX: DOKKA,
  SAP,
  NETSUITE,
  DEFAULT_ERP,
  ACUMATICA,
  HASH_WEB,
};

type Props = {|
  classes: {|
    [key: string]: string,
  |},
  erpType: erpNamesType,
  className?: string,
  ...HTMLImageElement,
|};

const ERPLogo: React.StatelessFunctionalComponent<Props> = ({ classes, className, erpType, ...rest }: Props) => (
  <img src={images[erpType]} className={cx(classes.logo, className)} {...rest} alt={erpType} />
);

export default withStyles(sheet)(ERPLogo);
